


import { Component, Vue, Prop } from "vue-property-decorator";
import Axios from "axios";
import { BASE_API_URL } from "@/config";
import PreRequisiteHeader from "@/views/preRequisiteHeader/PreRequisiteHeader.vue";

@Component({
  components: {
    PreRequisiteHeader
  }
})
export default class ThankYouComponent extends Vue {
  public message = null;

  @Prop({ required: true }) private userId: string;
  @Prop({ required: true }) private userEmail: string;

  mounted() {
    // This is the convertion TAG for the Thank You page
    (<any>window).gtag('event', 'conversion', {'send_to': 'AW-737803097/olFdCN7P-M0BENn2598C'});
  }

  public async resendVerificationLink() {
    try {
      const resendEmail:any = {
        _id: this.userId,
        email: this.userEmail,
        action: 'resend',
        sendEmail: false,
      };

      await Axios.post(
        BASE_API_URL + "authentication/resendEmailVerificationLink",
        {
          userInfo: [ resendEmail ],
          userType: "Broker",
        }
      );

      this.message = "If the account email is valid, a verification link will be sent to you.";
      this.$snotify.success(this.message);
    } catch (error) {
      console.log(error.response);
    }
  }
}
